<template>
  <div class="col">
    <!-- Start  Region Schools -->
    <server-table
      :DataItems="regionAdminSchools"
      :count="regionAdminSchoolsCount"
      @Refresh="Refresh"
      :useFilter="false"
      :enable_export="false"
      :action="false"
    >
      <template #columns>
        <el-table-column
          prop="school_admin"
          :label="$t('admin_dashboard_type.region_admin_name')"
        >
        </el-table-column>
        <el-table-column
          prop="name"
          :label="$t('admin_dashboard_type.school_name')"
        >
          <template slot-scope="scope">
            <router-link
              :to="`/region-admin/${$route.params.id}/${scope.row.id}`"
            >
              {{ scope.row.name }}
            </router-link>
          </template>
        </el-table-column>
        <el-table-column
          prop="students_count"
          :label="$t('admin_dashboard_type.students_number')"
        >
        </el-table-column>
        <el-table-column prop="email" :label="$t('admin_dashboard_type.email')">
        </el-table-column>
        <el-table-column
          prop="country"
          :label="$t('admin_dashboard_type.country')"
        >
        </el-table-column>
        <el-table-column prop="city" :label="$t('admin_dashboard_type.city')">
        </el-table-column>
      </template>
    </server-table>
    <!-- End  Region Schools -->
  </div>
</template>

<script>
import ServerTable from "../../components/DataTablePagination.vue";
import { mapGetters } from "vuex";
export default {
  components: { ServerTable },
  mounted() {
    this.Refresh();
  },
  computed: {
    ...mapGetters({
      loadingValue: ["schools/load"],
      regionAdminSchools: ["schools/regionAdminSchools"],
      regionAdminSchoolsCount: ["schools/regionAdminSchoolsCount"],
    }),
    offset() {
      if (this.$route.query.currentPage && this.$route.query.perPage) {
        return (this.$route.query.currentPage - 1) * this.$route.query.perPage;
      } else {
        return 0;
      }
    },

    limit() {
      if (this.$route.query.perPage) {
        return this.$route.query.perPage - 1;
      } else {
        return 4;
      }
    },
  },
  methods: {
    Refresh(
      query = {
        offset: this.offset,
        limit: this.limit,
        // region_admin_id: this.$route.params.id,
      }
    ) {
      this.$store.dispatch(
        "schools/handlerGetRegionAdminSchools",
        {
          region_admin_id: this.$route.params.id,
          query: query,
        }

        // region_admin_id: this.$route.params.id,
      );
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .cell {
  text-align: center;
  word-break: break-word;
}
</style>
